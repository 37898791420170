import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-demo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_frame = _resolveComponent("m-frame")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_m_frame, {
      routeList: $setup.menuList,
      "alive-include": "Home",
      deleteApi: "/criteo-index-delete.html",
      title: "Demo",
      "del-messages": $setup.messageList
    }, null, 8, ["routeList", "del-messages"])
  ]))
}